import {
  Configs,
  ContentModelConfigMergedBase,
  getContentViewFunction,
  ListWrapper,
} from '@dudagroup/editor'
import {
  ContentEditAction,
  ContentEditActionEnum,
} from '@dudagroup/editor/lib/client/control/contentReducer'
import {SchemaPath} from '@dudagroup/editor/lib/client/interfaces/utilTypes'
import {nanoid} from 'nanoid'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Grid, Panel, Tree, Divider} from 'rsuite'
import {NavigationDrawer} from './navigationDrawer'

interface Navigation {
  navigation: ListWrapper<NavigationNode>[]
}

interface NavigationNode {
  id: string
  labelI18n: {
    [key: string]: string
  }
  label: string
  refKey?: string
  visible?: true
  link?: any
  childContents?: any[]
  children?: ListWrapper<NavigationNode>[]
}

interface NavigationNodeTree {
  id: string
  labelI18n: {
    [key: string]: string
  }
  label: string
  refKey?: string
  visible?: true
  layer?: any
  link?: any
  expand?: boolean | null
  children?: NavigationNodeTree[]
  childContents?: any[]
  parentForContentOfType?: string
}

export interface CurrentNode {
  path: SchemaPath
  node: any
}

interface NavigationViewProps {
  content: Navigation
  handleChange: any
  disabled: boolean
  dispatch: React.Dispatch<ContentEditAction>
  configs: Configs
  contentModelConfigMerged: ContentModelConfigMergedBase
  langLaneL: string
  langLaneR: string
  langUi: string
}
const NavigationView = (props: NavigationViewProps) => {
  const {content, langLaneL, langLaneR, dispatch, configs, contentModelConfigMerged, langUi} = props
  const [currentNode, setCurrentNode] = useState<CurrentNode | undefined>(undefined)
  const {t} = useTranslation()

  const navigation: NavigationNodeTree[] = transformFromWePublishToRsuiteSchema(
    langLaneL,
    content.navigation
  )

  const tree = (
    <>
      <Button
        appearance="ghost"
        onClick={() => {
          const n = transformFromRsuiteToWePublishSchema(langLaneL, cleanupNodes(navigation))
          dispatch({
            type: ContentEditActionEnum.update,
            path: ['navigation'],
            value: [...n, createEmptyNavigationNode()],
          })
        }}>
        {t('treeNavigation.addNode')}
      </Button>
      <Divider>{t('treeNavigation.navigationTree')}</Divider>
      <Tree
        data={cleanupNodes(navigation)}
        valueKey="id"
        draggable
        defaultExpandAll
        style={{marginTop: 20, minHeight: 3000}}
        onSelect={(activeNode) => {
          const p = getPathForId(activeNode.id, navigation)
          if (p) {
            setCurrentNode({
              node: activeNode,
              path: p,
            })
          }
        }}
        onDrop={({createUpdateDataFunction}: any) => {
          const newData = transformFromRsuiteToWePublishSchema(
            langLaneL,
            cleanupNodes(createUpdateDataFunction(navigation))
          )

          dispatch({
            type: ContentEditActionEnum.update,
            path: ['navigation'],
            value: newData,
          })
        }}
      />

      {currentNode && (
        <NavigationDrawer
          configs={configs}
          dispatch={dispatch}
          langLaneL={langLaneL}
          langLaneR={langLaneR}
          langUi={langUi}
          contentModelSchema={contentModelConfigMerged.schema}
          currentNode={currentNode}
          deleteCurrentNode={(currentNode) => {
            const path = transformFromRsuiteToWePublishPath([...currentNode.path])
            const index = path.splice(path.length - 1, 1)[0] as number
            dispatch({
              type: ContentEditActionEnum.splice,
              path: path,
              start: index,
              delete: 1,
            })
            setCurrentNode(undefined)
          }}
          setCurrentNode={setCurrentNode}></NavigationDrawer>
      )}
    </>
  )
  return (
    <Grid>
      <br></br>
      <br></br>
      <Panel
        bordered
        style={{
          overflow: 'visible',
        }}>
        {tree}
      </Panel>
    </Grid>
  )
}

export const getNavigationView: getContentViewFunction = (
  content: Navigation,
  handleChange: any,
  disabled: boolean,
  dispatch: React.Dispatch<ContentEditAction>,
  configs: Configs,
  contentModelConfigMerged: ContentModelConfigMergedBase,
  langLaneL,
  langLaneR,
  langUi
) => {
  return (
    <NavigationView
      {...{
        content,
        handleChange,
        disabled,
        dispatch,
        configs,
        contentModelConfigMerged,
        langLaneL,
        langLaneR,
        langUi,
      }}></NavigationView>
  )
}

function cleanupNodes(nodes: NavigationNodeTree[]) {
  const cleanedNavi: any = nodes.map((node) => {
    const cleanNode: NavigationNodeTree = {
      id: node.id,
      label: node.label,
      labelI18n: node.labelI18n,
      refKey: node.refKey,
      visible: node.visible,
    }
    if (node.childContents) {
      cleanNode.childContents = node.childContents
    }
    if (node.children) {
      cleanNode.children = cleanupNodes(node.children)
    }
    if (node.link) {
      cleanNode.link = node.link
    }
    if (node.parentForContentOfType) {
      cleanNode.parentForContentOfType = node.parentForContentOfType
    }
    return cleanNode
  })
  return cleanedNavi
}

function createEmptyNavigationNode(): ListWrapper<NavigationNode> {
  const id = nanoid()
  const navigationNode: NavigationNode = {
    labelI18n: {
      en: 'New Node',
      de: 'Neuer Knotenpunkt',
      fr: 'New Node',
      it: 'New Node',
    },
    label: 'New Node',
    refKey: '0-0',
    id: id,
    visible: true,
    link: undefined,
    childContents: [],
    children: [],
  }
  return {
    id: id,
    expanded: false,
    content: navigationNode,
  }
}

function transformFromWePublishToRsuiteSchema(
  lang: string,
  navigationNode: ListWrapper<NavigationNode>[]
): NavigationNodeTree[] {
  if (!navigationNode) {
    return []
  }

  const n: NavigationNodeTree[] = navigationNode.map((node) => {
    const n = {...node.content} as NavigationNodeTree
    n.label = n.labelI18n[lang]
    if (node.content.children && node.content.children.length > 0) {
      n.children = transformFromWePublishToRsuiteSchema(lang, node.content.children)
    } else {
      n.children = undefined
    }
    return n
  })
  return n
}

export function transformFromRsuiteToWePublishSchema(
  lang: string,
  navigationNode: NavigationNodeTree[]
): ListWrapper<NavigationNode>[] {
  if (!navigationNode) {
    return []
  }

  const r: ListWrapper<NavigationNode>[] = navigationNode.map((node) => {
    const n: ListWrapper<NavigationNode> = {
      id: node.id,
      expanded: false,
      content: {...node} as NavigationNode,
    }
    if (node.children) {
      n.content.children = transformFromRsuiteToWePublishSchema(lang, node.children)
    }
    return n
  })
  return r
}

export function transformFromRsuiteToWePublishPath(path: SchemaPath): SchemaPath {
  if (!path) {
    return []
  }

  return path.reduce((accu, item, index, array) => {
    accu.push(item)
    if (typeof item === 'number' && array.length - 1 > index) {
      accu.push('content')
    }
    return accu
  }, [] as SchemaPath)
}

function getPathForId(
  id: string,
  navigationNode?: NavigationNodeTree[],
  path: SchemaPath = ['navigation']
): SchemaPath | undefined {
  if (!navigationNode) {
    return
  }

  let i = 0
  for (const node of navigationNode) {
    if (node.id === id) {
      return path.concat(i)
    }
    const r = getPathForId(id, node.children, [...path, i, 'children'])
    if (r) {
      return r
    }
    i++
  }
  return
}
